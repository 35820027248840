<template>
  <div class="row bg-theam-seconday">
    <div class="col-md-12">
      <form action="">
        <div class="group">
          <label class="group-label">Field Setting</label>
          <div class="group-attribute-container">
            <div class="row mb-2">
              <div class="col-md-5 mr-0 pr-0">Title *</div>
              <div class="col-md-7 ml-0 pl-0">
                <input
                  type="input"
                  v-model="formData.title"
                  class="form-control"
                  placeholder="Title here"
                  required
                />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-5 mr-0 pr-0">Field Type*</div>
              <div class="col-md-7 ml-0 pl-0">
                <select v-model="formData.type" class="form-control" required>
                  <option value="0">Addition</option>
                  <option value="1">Deduction</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5 mr-0 pr-0">VAT</div>
              <div class="col-md-7 ml-0 pl-0">
                <select v-model="formData.vat" class="form-control" required>
                  <option value="1">VAT able</option>
                  <option value="0">Non Vatable</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-12 text-right mt-3">
      <button class="btn btn-sm btn-primary" @click.prevent="addNewExtraField">
        Add
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      formData: {
        title: "",
        slug: "",
        vat: 0,
        type: 0,
        amount: 0,
        checked: true,
      },
      extraFieldExist: false,
    };
  },
  computed: {
    ...mapGetters(["eventMessage"]),
    ...mapGetters("purchase", ["extraFields", "selectedNewExtra"]),
  },
  methods: {
    addNewExtraField() {
      this.extraFieldExist = false;
      if (this.formData.title) {
        this.formData.slug = this.generateSlug(this.formData.title);
        this.extraFields.forEach((el) => {
          if (el.slug == this.formData.slug) {
            this.extraFieldExist = true;
          }
        });
        if (this.extraFieldExist == true) {
          this.setNotification("Extra Field already exist.");
        } else {
          this.$store.commit(
            "purchase/setSelectedNewExtra",
            this.formData.slug
          );
          this.$store.commit("purchase/setExtraFields", this.formData);
          //close modal
          this.$bvModal.hide("modal-multi-2");
        }
      } else {
        this.setNotification("Title field is required.");
      }
    },
    setNotification(err) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", err);
    },
    generateSlug(value) {
      return value
        .toLowerCase()
        .replace(/-+/g, "")
        .replace(/\s+/g, "-")
        .replace(/[^a-z0-9-]/g, "");
    },
  },
};
</script>